import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/principal/Hero";
import Feature from "../sections/principal/Feature";
import ContentOne from "../sections/principal/ContentOne";
import ContentTwo from "../sections/principal/ContentTwo";
import ContentThree from "../sections/principal/ContentThree";
import Nfe from "../sections/principal/NFE";
import Nfse from "../sections/principal/NFSE";
import Nfce from "../sections/principal/NFCE";
import Cnpj from "../sections/principal/CNPJ";
import Cep from "../sections/principal/CEP";
import Cte from "../sections/principal/CTE";
import Mdfe from "../sections/principal/MDFE";
import NFCom from "../sections/principal/NFCOM";
import Pricing from "../sections/principal/Planos";
import Parceiros from "../sections/principal/Parceiros";

const IndexPage = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <>
              <a className="btn header-btn rounded-5" style={{background:"rgb(51, 57, 141", color: "white"}}  href={"https://console.nuvemfiscal.com.br"}>
                   Comece Grátis
              </a>
            </>
          ),
          footerStyle: "style3",
        }}
      >
        <Hero className="bg-default-3 pt-25 pt-lg-29" />
        <Feature className="pt-4 pt-lg-18 pb-15 pb-lg-25 border-bottom border-default-color-3" />
        <ContentOne className="pt-27 pt-lg-35 pb-lg-34 " />
        <ContentTwo className="pb-13 pb-lg-34" />
        <Nfe className="pt-27 pt-lg-35 pb-lg-34" />
        <Nfse className="pt-27 pt-lg-35 pb-lg-34"/>
        <Nfce className="pt-27 pt-lg-35 pb-lg-34"/>
        <Cnpj className="pt-27 pt-lg-35 pb-lg-34"/>
        <Cep className="pt-27 pt-lg-35 pb-lg-34"/>
        <Cte className="pt-27 pt-lg-35 pb-lg-34"/>
        <Mdfe className="pt-27 pt-lg-35 pb-lg-34"/>
        <NFCom className="pt-27 pt-lg-35 pb-lg-34"/>
        <ContentThree className="pt-27 pt-lg-35 pb-lg-34 " />
        <Pricing className="pt-13 pt-lg-25 pb-8 pb-lg-22 " />
  {/*       <Parceiros className="bg-default-1 pt-13 pt-md-17 pt-lg-24 pb-13 pb-md-14 pb-lg-23" />   */}
      </PageWrapper>
    </>
  );
};
export default IndexPage;
